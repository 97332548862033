import React, { useState } from "react"
import { useForm } from "react-hook-form";
import { graphql } from "gatsby"
import { getImage } from "gatsby-plugin-image"
import { convertToBgImage } from "gbimage-bridge"
import BackgroundImage from "gatsby-background-image"
import Seo from 'gatsby-plugin-wpgraphql-seo'
import axios from "axios"

export const query =  graphql`
  query TEMPLATE_WP_CONTACT_PAGE_QUERY(
    $id: String!
  ) {
    wpPage(id: { eq: $id }) {
      ...pageData
      ...pageVariables
    }
  }
`

const Page = ({ data = {} }) => {
  const { wpPage: page = {} } = data
  const { 
    pageVariables = {},
    pageMeta = {},
    smartcrawlSeo = {} 
  } = page
  const { register, handleSubmit, formState: { errors } } = useForm();
  const [result, setResult] = useState("");
  const [submitting, setSubmitting] = useState(false)
  const image = pageMeta.headerImage && convertToBgImage(getImage(page.pageMeta.headerImage.localFile))

  const onSubmit = async (data) => {
    setSubmitting(true)
    axios.post(
      process.env.GATSBY_CONTACT_FORM_ENDPOINT, 
      data
    ).then(function (response) {
      if (response.status !== 200) {
        setResult(response.statusText)
      }
      setResult("Thank you, your message has been sent")
    }).catch(function (error) {
      console.log(error)
      setSubmitting(false)
    })
  };
 
  return (
    <React.Fragment>
      <Seo post={data.wpPage} />
      {pageVariables.primaryColour && 
        <style dangerouslySetInnerHTML= {{__html: `
        :root {
          --bs-primary: ${pageVariables.primaryColour};
        }
        `}} />
      }
      <main>
        {image &&
          <BackgroundImage 
            {...image} 
            className="header-image"
          />
        }
        <div className="container-fluid bg-primary py-5">
          <h1 className="text-center text-light mb-0">{page.title}</h1>
        </div>

        <div className="container pt-5">
          <div dangerouslySetInnerHTML={{ __html: page.content }} />
        </div>
        
        <div className="container pb-5">
          {result 
            ? <div className="">{result}</div>
            : <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row g-3">
                <div className="col-12 col-md-6">
                  <input type="text" className={"form-control " + (errors.firstName && 'is-invalid')} placeholder="First name" aria-label="First name" {...register("firstName", {required: true, maxLength: 80})} />

                </div>
                <div className="col-12 col-md-6">
                  <input type="text" className={"form-control " + (errors.lastName && 'is-invalid')} placeholder="Last name"  {...register("lastName", {required: true, maxLength: 80})} />
                </div>
                <div className="col-12">
                  <input type="email" className={"form-control " + (errors.email && 'is-invalid')} name="email" placeholder="Email" {...register("email", {required: true, pattern: /^\S+@\S+$/i})} />
                </div>
                <div className="col-12">
                  <textarea className={"form-control " + (errors.message && 'is-invalid')} name="message" rows="4" placeholder="Your message" {...register("message", {required: true})} ></textarea>
                </div>
              </div>
              <div className="d-flex justify-content-end">
                <button type="submit" className="btn btn-primary mt-3" disable={submitting}>
                  {submitting 
                    ? 'Sending...'
                    : 'Submit'
                  }</button>
              </div>
            </form>
          }
        </div>
      </main>
    </React.Fragment>
  )
}

export default Page